export default function XLorkClient(props) {
  this.props = props;

  let {
    settings = {},
    licenseKey,
    user,
    origin = 'https://app.xlork.com',
    onCancel = function (response) {
      console.log('[onCancelDefault]', response);
    },
    onComplete = function (response) {
      console.log('[onCompleteDefault]', response);
    },
  } = this.props;

  settings = Object.assign(
    {},
    {
      title: 'Data',
      allowInvalidSubmit: true,
      theme: null,
      maxRecords: 0,
      sampleUrl: null,
      columns: [],
      display: 'popup',
      header: true,
      branding: false,
      history: false,
      social: true,
    },
    settings
  );

  const defaultChannel = '__xlork';
  const embedURL = origin + '/embed/' + licenseKey;
  const iframeHtml = `<div class=${
    settings?.display == 'inline' ? '__xlork_iframe_container _inline' : '__xlork_iframe_container'
  }><div class="__xlork_loader" id="__xlork_loader" style="height:100%"><div class="loader_ripple"><div></div><div></div></div><span> Connecting ...</span></div><iframe name="__xlork_iframe"  id="__xlork_iframe" src=${embedURL} frameBorder="0" style="height:0%"  allowFullScreen ></iframe> </div>`;
  const styles = `.__xlork_hidden {
    display: none;
 }
  .__xlork_modal_open {
    overflow: hidden;
 }
  .__xlork_btn_default {
    background-color: #7b66f0;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 600;
 }
  .__xlork_error {
    padding: 8px;
    border: 1px dashed;
    color: red;
    background: #f00 8;
 }
  .__xlork_iframe_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    box-sizing: border-box;
    overflow: hidden;
    flex-direction: column;
    backdrop-filter: blur(4px);
    min-height: 400px;
    z-index: 2147483647;
 }
  .__xlork_iframe_container iframe {
    width: 100%;
    height: 100%;
    position: relative;
    border-width: 0;
    overflow: hidden;
    z-index: 9999;
 }
  .__xlork_iframe_container .__xlork_loader {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    align-items: center;
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 500;
 }
  .__xlork_iframe_container .__xlork_loader .loader_ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
 }
  .__xlork_iframe_container .__xlork_loader .loader_ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: loader_ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
 }
  .__xlork_iframe_container .__xlork_loader .loader_ripple div:nth-child(2) {
    animation-delay: -0.5s;
 }
  .__xlork_iframe_container._inline {
    background: transparent;
    display: flex;
    height: 100%;
    width: 100%;
    justify-self: center;
    align-self: center;
    backdrop-filter: blur(4px);
    position: relative;
 }
  .__xlork_iframe_container._inline .__xlork_loader {
    color: #707070;
 }
  .__xlork_iframe_container._inline .__xlork_loader .loader_ripple div {
    border: 4px solid #707070;
 }
  @keyframes loader_ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
   }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
   }
 }
  `;
  let allowLoadIframe = { error: true, message: '' };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const validateClientConfig = ({ settings, licenseKey, user, embedURL, onComplete, onCancel }) => {
    let _tmpError = '';
    if (!licenseKey) {
      _tmpError = '[REQUIRED] A Valid licenseKey is required.';
    } else if (!user) {
      _tmpError = `[REQUIRED] user not defined.`;
    } else if (!user?.email) {
      _tmpError = `[REQUIRED] email(unique) not defined.`;
    } else if (!embedURL) {
      _tmpError = `[REQUIRED] embedURL not defined.`;
    } else if (onComplete && typeof onComplete !== 'function') {
      _tmpError = `[REQUIRED] onComplete should be function type.`;
    } else if (onCancel && typeof onCancel !== 'function') {
      _tmpError = `[REQUIRED]  onCancel should be function type.`;
    } else if (!settings) {
      _tmpError = `[REQUIRED] setting not defined.`;
    } else if (!settings?.hasOwnProperty('columns')) {
      _tmpError = `[REQUIRED] should have required property 'columns'`;
    } else if (!settings?.columns?.length > 0) {
      //_tmpError = `[REQUIRED] should have atlest one column in  'columns'`;
    } else {
      settings?.columns?.forEach((s) => {
        if (!(s.label && s.key)) {
          _tmpError = `[REQUIRED] each column should have label & key in  'columns'`;
          return false;
        }
      });
    }
    allowLoadIframe = { error: _tmpError ? true : false, message: _tmpError };
    openIframe(allowLoadIframe);
  };

  const openIframe = async (allowLoadIframe) => {
    if (allowLoadIframe?.error && allowLoadIframe?.message) {
      alert(allowLoadIframe?.message);
    } else {
      while (document.readyState != 'complete') {
        await delay(100);
      }
      appendIframe(iframeHtml, '__xlork_main_container');
    }
  };

  const appendIframe = function (src, id) {
    const el = document.getElementById(id);
    el && el.remove();
    let container = document.body || document.getElementsByTagName('body')[0];
    let div = document.createElement('div');
    div.id = id;
    div.innerHTML = src;
    container.appendChild(div);
  };

  const recivedMessageFromIframe = (event) => {
    const data = event?.data;
    if (typeof data == 'object' && data?.channel == defaultChannel) {
      switch (data?.type) {
        case '[IFRAME LOADED]':
          const eventData = {
            type: '[CONFIG INITILIZE]',
            channel: defaultChannel,
            settings,
            licenseKey,
            user,
          };
          window.frames['__xlork_iframe'].postMessage(eventData, '*');
          try {
            document.getElementById('__xlork_loader').style.display = 'none';
            document.getElementById('__xlork_iframe').style.height = '100%';
          } catch (error) {
            console.log(error);
          }

          break;

        case '[onComplete]':
          onComplete && onComplete(data.value);
        case '[onCancel]':
          destroy();
          onCancel && onCancel();
          break;
      }
    }
  };

  const appendStyle = (id) => {
    const el = document.getElementById(id);
    el && el.remove();
    const style = document.createElement('style');
    style.textContent = styles;
    style.id = id;
    style.type = 'text/css';
    document.head.appendChild(style);
  };

  const destroy = () => {
    window.removeEventListener('message', recivedMessageFromIframe, false);
    try {
      document.body.classList.remove('__xlork_modal_open');
      const el = document.getElementById('__xlork_main_container');
      el && el.remove();
    } catch (error) {
      console.log('__xlork_modal_open', error);
    }
  };

  this.init = () => {
    appendStyle('__xlork_styles');
    window.addEventListener('message', recivedMessageFromIframe, false);
    validateClientConfig({ settings, licenseKey, user, embedURL, onComplete, onCancel });
  };
}
